/* Import Bootstrap and its default variables */
$blue: #1f5d9f;
$black: #333333;
$green: #4cb93a;
$red: #fd4242;

@import '~bootstrap/dist/css/bootstrap.min.css';

* {
  font-family: 'Outfit', sans-serif;
}

.blue-header {
  position: relative !important;
}

.rounded-main-border {
  background-color: white;
  position: absolute;
  border-radius: 25px;
  top: 8rem;
}

.header {
  background-color: $blue;
  min-height: 0.5rem;
  text-align: center;
  width: 100%;

  &__logo {
    height: '100%';
    width: 200px;
  }
}

.btn-send-form {
  background-color: $blue;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  width: 100%;

  &__w50 {
    width: 50%;
  }
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: '';
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.loading {
  width: 7em;
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.img-success {
  margin-top: 2.5rem;
  width: 15em;
}

.img-error {
  margin-top: 2.5rem;
  width: 20em;
}

.text-success {
  color: $green;
}

.text-error {
  color: $red;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-bottom: 7.5rem;
  margin-top: 7.5rem;
  width: 7em;

  &::after {
    content: '';
    height: 5.5rem;
    width: 5.5rem;
    border: 15px solid transparent;
    border-top-color: gray;
    border-right-color: gray;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}

.spinner-mini {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 3em;

  &::after {
    content: '';
    height: 1.5rem;
    width: 1.5rem;
    border: 5px solid transparent;
    border-top-color: gray;
    border-right-color: gray;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.icon-images {
  max-width: 5rem;
}

.image-failure {
  width: 100%;
  height: 5rem;
  background-size: 5rem;
  background-repeat: no-repeat;
  background-position: center;
}

[data-category-type='mechanic'] {
  background-image: url('./images/mechanic_gray.png');

  &.active {
    background-image: url('./images/mechanic_blue.png');
  }
}

[data-category-type='product'] {
  background-image: url('./images/product_gray.png');

  &.active {
    background-image: url('./images/product_blue.png');
  }
}

[data-category-type='money'] {
  background-image: url('./images/money_grey.png');

  &.active {
    background-image: url('./images/money_blue.png');
  }
}
